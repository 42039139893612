document.addEventListener('DOMContentLoaded', () => {
  const checkBox = document.getElementById('terms_agree');
  const loginBtn = document.getElementById('login_btn');
  if (!checkBox || !loginBtn) {
    return;
  }

  checkBox.addEventListener('input', () => {
    if (checkBox.checked) {
      loginBtn.classList.remove('u-gray-out');
    } else {
      loginBtn.classList.add('u-gray-out');
    }
  });
});
