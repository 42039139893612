import { csrfToken } from '@rails/ujs';

$(document).ready(function(){
  $("a[href^='http']").not("[href*='tokaijoint2020.jp']").on('click', function() {
    var src_path = location.pathname;
    var dst_url = $(this).attr("href");

    $.ajax({
      type: 'POST',
      url: '/api/user_external_link_logs',
      headers: {
        'X-CSRF-Token': csrfToken()
      },
      data: {
        src_path: src_path,
        dst_url: dst_url
      },
      dataType: 'json',
      timeout: 10000
    });
  });
});
