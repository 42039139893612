require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require.context("../images/front", true);

import '@fortawesome/fontawesome-free/css/all';

import "../styles/front/main"

import "../scripts/front/click_external_link"
import "../scripts/front/smooth_scroll"
import "../scripts/front/sp_menu"
import "../scripts/front/tab"
import "../scripts/front/login"
