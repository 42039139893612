$(document).ready(function() {
  const windowHeight = $(window).height();
  $('.drawr').css('height', windowHeight);

  $('.hamburger').click(function(){
    if ($('.drawr').is(':animated')) {
      return false;
    } else {
      $('.drawr').animate({width:'toggle'});
      return false;
    }
  });

  $(document).click(function(event) {
    if (!$(event.target).closest('.drawr').length) {
      $('.drawr').hide();
    }
  });
});
